import React from 'react'

import { Grid } from '@material-ui/core'

import ProductGridCard from '~/components/cards/ProductCard/ProductGridCard'
import Section from '~/components/shared/Section'

import Paths from '~/utils/paths'

const placeholder = Array.from(new Array(4))

const FeaturedListingSection = ({
  data,
  loading,
  isFetchedAfterMount
}: {
  data: any
  loading?: boolean
  isFetchedAfterMount?: boolean
}) => {
  const newData = data?.length ? data : placeholder

  return (
    <Section
      displayLink
      linkHref={Paths.search.index}
      title="Featured Listings"
      empty={isFetchedAfterMount && !data?.length}
      unmountOnEmpty
      loading={loading}
    >
      <Grid container spacing={2}>
        {newData?.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item?.id || index}>
              <ProductGridCard
                data={item}
                loading={!data?.length}
                // showDealerOnHover={!isMobile}
                fullHeight
              />
            </Grid>
          )
        })}
      </Grid>
    </Section>
  )
}

export default FeaturedListingSection
